import React from 'react';

function Select(props: any) {
  return (
    <select className="form-select" required={props.field.mandatory} name={props.field.name} onChange={props.handleChange}>
      <option value="">Escolha uma opção</option>
      {
        props.field.options.map((option: any, index: any) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))
      }
    </select>
  );
}

export default Select;