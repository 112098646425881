import React from 'react';

function Checkin() {
  return (
    <>
      <h1>Checkin</h1>
    </>
  );
}

export default Checkin;