import React from 'react';

function Radio(props: any) {
  return (
    <>
      {
        props.field.options.map((option: any, index: any) => (
          <div className="form-check" key={index}>
            <input className="form-check-input" type="radio" required={props.field.mandatory} name={props.field.name} onChange={props.handleChange} id={option.id} />
            <label className="form-check-label">
              { option.label }
            </label>
          </div>
        ))
      }
    </>
  );
}

export default Radio;