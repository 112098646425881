export default function validateQuiz(state: any, fields: any) {
  let errors = {};

  fields.map((field: any) => {
    if (!state[field.name] || state[field.name] === null) {
      errors = { ...errors, [field.name]: 'O campo ' + field.label + ' está vazio.' };
    }
  })

  return errors;
}