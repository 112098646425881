import { useState } from 'react';

const useForm = (validate, formJSON) => {

  const [state, setState] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(state, formJSON.fields));

    setIsSubmitting(true);
  };

  const handleChange = (e) => 
  {
    e.persist()
    switch (e.target.type) {
      case 'radio':
        setState(state => ({ ...state, [e.target.name]: e.target.id }))
        break;
        
      case 'checkbox':
        const targetName = e.target.name
        const total = state[targetName] ? state[targetName] : 0
        if (e.target.checked) {
          setState(state => ({ ...state, [e.target.name]: total + parseInt(e.target.id) }))
        } else {
          setState(state => ({ ...state, [e.target.name]: total - parseInt(e.target.id) }))
        }
        break;
    
      default:
        setState(state => ({ ...state, [e.target.name]: e.target.value }))
        break;
    }
  }

  return [state, handleChange, errors, handleSubmit, isSubmitting];

}

export default useForm;