import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Checkin from './pages/Checkin/Checkin';
import Quiz from './pages/Quiz/Quiz';

function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Quiz />} />
      <Route path="/checkin" element={<Checkin />} />
    </Routes>
  );
}

export default MainRoutes;