import React from 'react';

import Input from './Fields/Input';
import Radio from './Fields/Radio';
import Checkbox from './Fields/Checkbox';
import Select from './Fields/Select';

function Element(props: any) {

  function renderField() {
    switch (props.field.type) {
      case "text":
        return <Input field={props.field} handleChange={props.handleChange} errors={props.errors} />
      case "radio":
        return <Radio field={props.field} handleChange={props.handleChange} errors={props.errors} />
      case "checkbox":
        return <Checkbox field={props.field} handleChange={props.handleChange} errors={props.errors} />
      case "select":
        return <Select field={props.field} handleChange={props.handleChange} errors={props.errors} />
      default:
        return <h1>Não encontrado</h1>;
    }
  }

  return (
    <div className="form-group mb-3">
      <label>{ props.field.label }</label>
      { renderField() }
    </div>
  );
}

export default Element;