import React from 'react';

import formJSON from '../../form.json';
import Element from '../../components/Element/Element';
import Button from '../../components/Button/Button';
import useForm from '../../hooks/useForm';
import validateQuiz from '../../utils/validateQuiz'

function Quiz() {

  const [ state, handleChange, errors, handleSubmit, isSubmitting ] = useForm(validateQuiz, formJSON)

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>{ formJSON.title }</h1>
          <p>{ formJSON.description }</p>
          <form onSubmit={handleSubmit} noValidate>
            { formJSON.fields.map((field, index) => (
              <Element key={index} field={field} handleChange={handleChange} errors={errors} />
            ))}
            <Button isSubmitting={isSubmitting}>Enviar</Button>
            { JSON.stringify(errors)}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Quiz;