import React from 'react';

function Input(props: any) {
  return (
    <>
      <input type="text" className="form-control" required={props.field.mandatory} id={props.field.id} placeholder={props.field.placeholder} name={props.field.name} onChange={props.handleChange}></input>
    </>
  );
}

export default Input;